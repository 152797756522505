<script lang="ts" setup>
import { FormKit } from '@formkit/vue'
import type { ICompanyCreateBody } from '~/composables/useAPI/types'
import { ApiError } from '@shared'
import { useCompaniesStore } from '~/stores/companies'

import { toast } from 'vue3-toastify'
const { createCompany, createChildCompany } = useApiCompany()
const store = useCompaniesStore()

const form = ref<typeof FormKit>(null!)

const submitForm = async (body: ICompanyCreateBody & { parentId: string }) => {
  const node = form.value.node
  const requestOptions = {
    onSuccess: () => {
      toast.success('Division created')

      const { data: user } = useAuth()
      useAnalytics({
        name: 'Division creation',
        data: {
          Name: body.name,
          'Country ID': body.countryId,
          'Business Number': body.businessNumber,
          'Tax File Number': body.taxFileNumber,
          'Trading Name': body.tradingName,
          Branch: body.branch,
          Brands: body.brands,
          Notes: body.notes,
          'Created by': user.value?.email,
        },
      })
    },
    onError: () => {
      toast.error('Division creation failed')
    },
  }

  try {
    if (body.parentId) {
      await createChildCompany.mutateAsync(body, requestOptions)
    } else {
      await createCompany.mutateAsync(body, requestOptions)
    }
  } catch (e) {
    // Normalize errors to FormKit and display them in the form
    if (e instanceof ApiError && e.data) {
      const { localErrors, childErrors } = requestToFormkitErrors(e.data)
      node.setErrors(localErrors, childErrors)
      return
    }

    node.setErrors(['Something went wrong'])
  }
}
</script>

<template>
  <div class="w-full rounded-lg p-4 lg:w-1/2">
    <h1>Create a new division</h1>

    <FormKit
      ref="form"
      type="form"
      class="flex"
      :actions="false"
      @submit="submitForm"
    >
      <DivisionDetails />

      <div class="mt-6 flex justify-end">
        <Button type="submit" :loading="createCompany.isPending.value" class="">
          Create
        </Button>
      </div>
    </FormKit>
  </div>
</template>
